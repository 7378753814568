(function(window, document, undefined) {
    var mobileDetect = new MobileDetect(window.navigator.userAgent);

    function initialize() {
        its.ui.loading.hide();
        run();
    }

    function run() {
        var hideLoading = its.element.get().all('.hide-loading');

        TweenMax.set(hideLoading, {
            autoAlpha: 0.0,
            display: ''
        });

        TweenMax.to(hideLoading, 1, {
            autoAlpha: 1.0
        });
    }

    window.addEventListener('load', function load() {
        window.removeEventListener('load', load, false);
        $(document).trigger('ready');
        initialize();
    }, false);

    var element = {
        getBoundingClientRect: function(_element) {
            return _element.getBoundingClientRect();
        },
        getComputedStyle: function(_element, pseudoElement) {
            return window.getComputedStyle(_element, (pseudoElement || null));
        },
        getIsVisible: function(_element) {
            var computedStyle = element.getComputedStyle(_element);
            return !!(!(/none/i).test(computedStyle.display) && !(/hidden/i).test(computedStyle.visibility) && parseFloat(computedStyle.opacity, 10));
        },
        get: function(context) {
            context = (context || document);

            var all = function(selector) {
                return context.querySelectorAll(selector);
            };

            var one = function(selector) {
                return all(selector).item(0);
            };

            var extended = function(options) {
                var elements = {};

                for (var selector in options) {
                    var alias = options[selector],
                        _element = one(selector),
                        boundingClientRect,
                        computedStyle,
                        isVisible;

                    if (!_element) {
                        continue;
                    }

                    boundingClientRect = element.getBoundingClientRect(_element);
                    computedStyle = element.getComputedStyle(_element);
                    isVisible = element.getIsVisible(_element);

                    if ((/(object|svg)/i).test(_element.tagName)) {
                        _element = _element.contentDocument.documentElement;
                    }

                    elements[alias] = {
                        element: _element,
                        boundingClientRect: boundingClientRect,
                        computedStyle: computedStyle,
                        isVisible: isVisible
                    };
                }

                return elements;
            };

            return {
                all: all,
                one: one,
                extended: extended
            };
        }
    };

    var ui = {
        loading: {
            toggle: function(state, callback) {
                state = !!(state || 0);
                callback = (callback || function(){});

                var _element = element.get().extended({
                    '.loading-overlay': 'overlay',
                    '.loading-spinner': 'spinner'
                }),
                isVisible = (_element.overlay.isVisible || _element.spinner.isVisible);

                if (state === isVisible) {
                    callback();
                    return;
                }

                TweenMax.set([_element.overlay.element, _element.spinner.element], {
                    autoAlpha: ((state) ? 0.0 : 1.0),
                    display: ''
                });

                TweenMax.to([_element.overlay.element, _element.spinner.element], 0.5, {
                    autoAlpha: ((state) ? 1.0 : 0.0),
                    onComplete: function() {
                        if (!state) {
                            TweenMax.set([_element.overlay.element, _element.spinner.element], {
                                display: 'none'
                            });
                        }

                        callback();
                    }
                });
            },

            hide: function(callback) {
                ui.loading.toggle(false, callback);
            },

            show: function(callback) {
                ui.loading.toggle(true, callback);
            }
        },

        viewport: {
            getSize: function() {
                var size = {
                    height: 0,
                    width: 0
                };

                if (mobileDetect.mobile()) {
                    size.width = document.documentElement.clientWidth;
                    size.height = document.documentElement.clientHeight;
                } else {
                    if (document.compatMode === 'CSS1Compat') {
                        size.width = window.innerWidth;
                        size.height = window.innerHeight;
                    } else {
                        size.width = ((typeof(document.documentElement.clientWidth) === 'number') ? document.documentElement.clientWidth : 0);
                        size.height = ((typeof(document.documentElement.clientHeight) === 'number') ? document.documentElement.clientHeight : 0);

                        if (!size.width || !size.height) {
                            size.width = document.body.clientWidth;
                            size.height = document.body.clientHeight;
                        }
                    }
                }

                size.width = Math.round(size.width);
                size.height = Math.round(size.height);

                return size;
            }
        }
    };

    window.its = {
        element: element,
        ui: ui
    };

    var quoteSwiper = {
        swiper: undefined,
        init: function() {
            quoteSwiper.swiper = $('.quote-swiper .swiper-wrapper').slick({
                infinite: true,
                dots: true,
                fade: true,
                arrows: false,
                draggable: false,
                customPaging : function(slider, i) {
                    var title = $('.swiper-slide', slider.$slides[i]).data('title'),
                        nav = '<span>' + title + '</span>';
                    return nav;
                }
            });
        }
    };

    $(document).on('ready', function() {
        $(window).trigger('resize');
    });
    $(window).on('resize', debounce(function() {
        if (quoteSwiper.swiper == undefined) {
            quoteSwiper.init();
        }
    }, 100));

    $('.datepicker').pickadate({
        selectMonths: true, // Creates a dropdown to control month
        selectYears: 15 // Creates a dropdown of 15 years to control year
    });
})(window, document);

/**
 * Custom Selectize disable on mobile option
 */
Selectize.define('disableInputOnMobile', function(options) {
    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) )
        {
            return 'iOS';
        }
        else if( userAgent.match( /Android/i ) )
        {
            return 'Android';
        }
        else
        {
            return null;
        }
    }

    if (this.settings.mode !== 'single' || options.disableOnMobile) re
    if ( getMobileOperatingSystem() === null ) return;
    var self = this;

    // override the setup method to add an extra "click" handler
    this.setup = (function() {
        var original = self.setup;
        return function() {
            original.apply(this, arguments);
            this.$control_input.attr('readonly',true);
        };
    })();
});


/**
 * David walsh debounce function
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
