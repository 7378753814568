(function($){
    var navigation = {
        _cardRise: function() {

        },
        _cardDrop: function() {

        },
        init: function() {
            var cards = $('#nav-modal li.card:not(:first-of-type)');
            var actionMenuItems = $('.navigation-menu-actions');

            // Add card entrance animation
            navigation.cardAnimation = new TimelineMax({ paused: true });
            navigation.cardAnimation.staggerTo($('#nav-modal li.card'), 1.5, { y: '+=60', ease: Elastic.easeOut }, 0.1);

            // Add action menu hover state
            var hoverTimeout;
            actionMenuItems.hover(function(){
                clearTimeout(hoverTimeout);
                cards.addClass('rise');
            }, function(){
                hoverTimeout = setTimeout(function(){
                    cards.removeClass('rise');
                }, 100);
            });

        },
        show: function() {
            $('#nav-modal').addClass('visible');
            navigation.cardAnimation.play();
        },
        hide: function() {
            $('#nav-modal').removeClass('visible');
            setTimeout(function(){
                navigation.cardAnimation.pause(0);
            }, 300);
        },
        showContact: () => {
            $('#contact-element').addClass('visible');
            $('#footer-trigger').addClass('display');

            var footerTopMenuItems = $('.contact-footer-top-menu-item'),
                timeline = new TimelineMax();

            timeline.staggerTo(footerTopMenuItems, 1.5, { y: '-=230', ease: Elastic.easeOut.config(1, 0.6) }, 0.1);
        },
        hideContact: () => {
            $('#contact-element').addClass('slide-away');
            $('#footer-trigger').removeClass('display');

            var footerTopMenuItems = $('.contact-footer-top-menu-item'),
                timeline = new TimelineMax();

            timeline.staggerTo(footerTopMenuItems, 1.5, { y: '+=230', ease: Elastic.easeOut.config(1, 0.6) }, 0.1);
            
            setTimeout(() => {
                $('#contact-element').removeClass('visible');
                $('#contact-element').removeClass('slide-away');
            }, 500)
        },
        showNavFooterModal: (element) => {
            element.addClass('active modal-over-modal')
        },
        hideNavFooterModal: () => {
            $('.email-us').removeClass('active modal-over-modal')
            $('.call-us').removeClass('active modal-over-modal')
            $('#nav-footer-modal-overlay').removeClass('active')
        }
    };

    $(document).on('ready', function() {
        navigation.init();
    });

    $('#nav-toggle').click(function() {
        navigation.show();
    });

    $('#nav-close').click(function() {
        navigation.hide();
    });

    $('#contact-toggle').click(() => {
        navigation.showContact();
    });

    $('#close-contact').click(() => {
        navigation.hideContact();
    });

    $('#call-modal-toggle').click(() => {
        navigation.showNavFooterModal($('.call-us'));
    })
    
    $('#call-close-button').click(() => {
        navigation.hideNavFooterModal();
    })

    $('#email-modal-toggle').click(() => {
        navigation.showNavFooterModal($('.email-us'));
    })
    
    $('#nav-footer-modal-overlay').click(() => {
        navigation.hideNavFooterModal()
    })


})(jQuery);