(function($){

    var form = {
        /**
         * Setup the selectize form elements
         * @private
         */
        _setupSelectize: function() {
            var options = {
                plugins:['disableInputOnMobile'],
                disableOnMobile: true
            };
            $('select').selectize(options);
        },

        /**
         * increases the amount in an input
         * @private
         */
        _increaseAmount: function($target) {
            var $input = $target.closest('.increment-number').find('input');

            $input.val(Number($input.val())+1);
        },

        /**
         * decreases the amount in an input
         * @private
         */
        _decreaseAmount: function ($target) {
            var $input = $target.closest('.increment-number').find('input');

            $input.val(Number($input.val())-1);
        },


        /**
         * Attach all event handlers
         * @private
         */
        _setupEventHandlers: function() {

            /*
            on click of button increase the amount of the closest input
              */
            // $('.add').click(form._increaseAmount);
            $('.add').on('click', function(e){
                var $this = $(e.target);
                if ($this.attr('disabled') === undefined){
                    form._increaseAmount($this);
                }
            });

            /*
            on click of button decreases the amount of the closest input
              */
            // $('.minus').click(form._decreaseAmount);
            $('.minus').on('click', function(e){
                var $this = $(e.target);
                if ($this.attr('disabled') === undefined){
                    form._decreaseAmount($this);
                }
            });



            //Partial form

            $('#hazmat-partial').change(function() {
                if(this.checked) {
                    $( '.unNumber-partial' ).prop( 'disabled', false );
                    $('.pg-dropdown-partial')[0].selectize.enable();
                }
                else {
                    $( '.unNumber-partial' ).prop( 'disabled', true );
                    $('.pg-dropdown-partial')[0].selectize.disable();
                }
            });

            $('#loose-partial').change(function() {
                if(this.checked) {
                    $( '#nspLengthPartial' ).prop( 'disabled', false );
                    $('#nspLengthPartial').val('40"');
                    $( '#nspWidthPartial' ).prop( 'disabled', false );
                    $('#nspWidthPartial').val('48"');
                }
                else {
                    $( '#nspLengthPartial' ).prop( 'disabled', true );
                    $( '#nspWidthPartial' ).prop( 'disabled', true );
                }
            });

            $('#palletized-partial').change(function() {
                if(this.checked) {
                    $( '#nspLengthPartial' ).prop( 'disabled', true );
                    $( '#nspWidthPartial' ).prop( 'disabled', true );
                }
                else {
                    $( '#nspLengthPartial' ).prop( 'disabled', true );
                    $( '#nspWidthPartial' ).prop( 'disabled', true );
                }
            });

            $('#non-standard-palletized-partial').change(function() {
                if(this.checked) {
                    $( '#nspLengthPartial' ).prop( 'disabled', false );
                    $('#nspLengthPartial').val('40"');
                    $( '#nspWidthPartial' ).prop( 'disabled', false );
                    $('#nspWidthPartial').val('48"');
                }
                else {
                    $( '#nspLengthPartial' ).prop( 'disabled', true );
                    $( '#nspWidthPartial' ).prop( 'disabled', true );
                }
            });

            $('#non-stackable-partial').change(function() {
                if(this.checked) {
                    $( '#stackableRadio1Partial' ).prop( 'disabled', true );
                    $( '#stackableRadio2Partial' ).prop( 'disabled', true );
                    $( '#stackableRadio3Partial' ).prop( 'disabled', true );
                    $( '#stackableWeightPartial' ).prop( 'disabled', true );
                    $( '#stackablePiecesPartial' ).prop( 'disabled', true );
                }
                else {
                    $( '#stackableRadio1Partial' ).prop( 'disabled', false );
                    $( '#stackableRadio2Partial' ).prop( 'disabled', false );
                    $( '#stackableRadio3Partial' ).prop( 'disabled', false );
                    $( '#stackableWeightPartial' ).prop( 'disabled', false );
                    $( '#stackablePiecesPartial' ).prop( 'disabled', false );
                }
            });

            //Equipment Collapse
            $('#van-etrack-partial').change(function() {
                $('#etrack-bars-partial').attr('disabled',!this.checked)
                $('#etrack-straps-partial').attr('disabled',!this.checked)
            });

            function disableEtrackPartial() {
                document.getElementById("etrack-bars-partial").disabled = true;
                document.getElementById("etrack-straps-partial").disabled = true;
            }

            function enableEtrackPartial() {
                document.getElementById("etrack-bars-partial").disabled = false;
                document.getElementById("etrack-straps-partial").disabled = false;
            }


            // function disableBtn() {
            //     // document.getElementsByClassName('.add').disabled = true;
            //     $('.add').prop('disabled', true)
            // }
            //
            // function enableBtn() {
            //     // document.getElementsByClassName('.add').disabled = false;
            //     $('.add').prop('disabled', false)
            // }

            $('.dry-van-options').on('click',function(){
                var id = $(this).attr('id');

                $('.dryVan-bars-partial .add').attr('disabled', true);
                $('.dryVan-bars-partial .minus').attr('disabled', true);
                $('.dryVan-straps-partial .add').attr('disabled', true);
                $('.dryVan-straps-partial .minus').attr('disabled', true);
                disableEtrackPartial();

                if (id === 'van-standard-partial'){
                    $( '#van-wood-partial' ).prop( 'checked', false );
                    $( '#van-plated-partial' ).prop( 'checked', false );
                    $( '#van-etrack-partial' ).prop( 'checked', false );
                } else if (id === 'van-wood-partial'){
                    $( '#van-standard-partial' ).prop( 'checked', false );
                } else if (id === 'van-plated-partial'){
                    $( '#van-standard-partial' ).prop( 'checked', false );
                    $( '#van-etrack-partial' ).prop( 'checked', false );
                } else if (id === 'van-etrack-partial'){
                    $( '#van-standard-partial' ).prop( 'checked', false );
                    $( '#van-plated-partial' ).prop( 'checked', false );

                    enableEtrackPartial();
                    $('.dryVan-bars-partial .add').attr('disabled', false);
                    $('.dryVan-bars-partial .minus').attr('disabled', false);
                    $('.dryVan-straps-partial .add').attr('disabled', false);
                    $('.dryVan-straps-partial .minus').attr('disabled', false);
                }
            });

            $('#collapse1Partial').change(function(){
                var primary = $('#dry-van-partial');
                var option1 = $('#van-standard-partial');
                var option2 = $('#van-wood-partial');
                var option3 = $('#van-plated-partial');
                var option4 = $('#van-etrack-partial');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#collapse1Partial').change(function(){
                var primary = $('#flatbed-partial');
                var option1 = $('#flatbed-standard-partial');
                var option2 = $('#flatbed-stepdeck-partial');
                var option3 = $('#flatbed-fourFoot-partial');
                var option4 = $('#flatbed-eightFoot-partial');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#collapse1Partial').change(function(){
                var primary = $('#specialized-partial');
                var option1 = $('#specialized-lowboy-partial');
                var option2 = $('#lowboy-gooseneck-partial');
                var option3 = $('#lowboy-either-partial');
                var option4 = $('#specilaized-curtain-partial');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#equipmentTruckload-3').change(function(){
                var primary = $('#specialized-lowboy-partial');
                var pickup = $('#lowboy-gooseneck-partial');
                var delivery = $('#lowbpy-either-partial');

                if (!primary.is(':checked')) {
                    $( pickup ).prop( 'checked', false );
                    $( delivery ).prop( 'checked', false );
                    pickup.prop('disabled', true);
                    delivery.prop('disabled', true);
                } else if (primary.is(':checked')) {
                    pickup.prop('disabled', false);
                    delivery.prop('disabled', false);
                }
            });

            $('#collapse1Partial').change(function(){
                var primary = $('#refrigeratedVan-partial');
                var option1 = $('#refridgerated-high-partial');
                var option2 = $('#refrigerated-low-partial');
                var option3 = $('#refrigerated-standard-partial');
                var option4 = $('#refrigerated-cube-partial');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });





            //    LTL form

            $('#hazmat-ltl').change(function() {
                if(this.checked) {
                    $( '.unNumber-ltl' ).prop( 'disabled', false );
                    $('.pg-dropdown-ltl')[0].selectize.enable();
                }
                else {
                    $( '.unNumber-ltl' ).prop( 'disabled', true );
                    $('.pg-dropdown-ltl')[0].selectize.disable();
                }
            });

            $('#loose-ltl').change(function() {
                if(this.checked) {
                    $( '#nspLengthltl' ).prop( 'disabled', false );
                    $('#nspLengthltl').val('40"');
                    $( '#nspWidthltl' ).prop( 'disabled', false );
                    $('#nspWidthltl').val('48"');
                }
                else {
                    $( '#nspLengthltl' ).prop( 'disabled', true );
                    $( '#nspWidthltl' ).prop( 'disabled', true );
                }
            });

            $('#palletized-ltl').change(function() {
                if(this.checked) {
                    $( '#nspLengthltl' ).prop( 'disabled', true );
                    $( '#nspWidthltl' ).prop( 'disabled', true );
                }
                else {
                    $( '#nspLengthltl' ).prop( 'disabled', true );
                    $( '#nspWidthltl' ).prop( 'disabled', true );
                }
            });

            $('#non-standard-palletized-ltl').change(function() {
                if(this.checked) {
                    $( '#nspLengthltl' ).prop( 'disabled', false );
                    $('#nspLengthltl').val('40"');
                    $( '#nspWidthltl' ).prop( 'disabled', false );
                }
                else {
                    $( '#nspLengthltl' ).prop( 'disabled', true );
                    $( '#nspWidthltl' ).prop( 'disabled', true );
                }
            });

            $('#non-stackable-ltl').change(function() {
                if(this.checked) {
                    $( '#stackableRadio1ltl' ).prop( 'disabled', true );
                    $( '#stackableRadio2ltl' ).prop( 'disabled', true );
                    $( '#stackableRadio3ltl' ).prop( 'disabled', true );
                    $( '#stackableWeightltl' ).prop( 'disabled', true );
                    $( '#stackablePiecesltl' ).prop( 'disabled', true );
                }
                else {
                    $( '#stackableRadio1ltl' ).prop( 'disabled', false );
                    $( '#stackableRadio2ltl' ).prop( 'disabled', false );
                    $( '#stackableRadio3ltl' ).prop( 'disabled', false );
                    $( '#stackableWeightltl' ).prop( 'disabled', false );
                    $( '#stackablePiecesltl' ).prop( 'disabled', false );
                }
            });


            $('#residential-ltl').change(function() {
                $('#accessorialsLTL-3').addClass('in');
            });

            $('#residential-ltl').on('click', function(){
                if (this.checked){
                    document.getElementById('liftgateRequired-ltl').checked = true;
                    // $('#accessorialsLTL-3').addClass('in');
                }
            });

            $('#pickupResidential-ltl').on('click', function(){
                if (this.checked){
                    document.getElementById('pickupLiftgate-ltl').checked = true
                }
            });

            $('#deliveryResidential-ltl').on('click', function(){
                if (this.checked){
                    document.getElementById('deliveryLiftgate-ltl').checked = true
                }
            });


            $('#collapse2LTL').change(function(){
                var primary = $('#tradeshow-ltl');
                var pickup = $('#pickupTradeshow-ltl');
                var delivery = $('#deliveryTradeshow-ltl');

                if (!primary.is(':checked')) {
                    $( pickup ).prop( 'checked', false );
                    $( delivery ).prop( 'checked', false );
                    pickup.prop('disabled', true);
                    delivery.prop('disabled', true);
                } else if (primary.is(':checked')) {
                    pickup.prop('disabled', false);
                    delivery.prop('disabled', false);
                }
            });

            $('#collapse2LTL').change(function(){
                var primary = $('#residential-ltl');
                var pickup = $('#pickupResidential-ltl');
                var delivery = $('#deliveryResidential-ltl');

                if (!primary.is(':checked')) {
                    $( pickup ).prop( 'checked', false );
                    $( delivery ).prop( 'checked', false );
                    pickup.prop('disabled', true);
                    delivery.prop('disabled', true);
                } else if (primary.is(':checked')) {
                    pickup.prop('disabled', false);
                    delivery.prop('disabled', false);
                }
            });

            $('#collapse2LTL').change(function(){
                var primary = $('#liftgateRequired-ltl');
                var pickup = $('#pickupLiftgate-ltl');
                var delivery = $('#deliveryLiftgate-ltl');

                if (!primary.is(':checked')) {
                    $( pickup ).prop( 'checked', false );
                    $( delivery ).prop( 'checked', false );
                    pickup.prop('disabled', true);
                    delivery.prop('disabled', true);
                } else if (primary.is(':checked')) {
                    pickup.prop('disabled', false);
                    delivery.prop('disabled', false);
                }
            });






            //    Truckload form

            $('#hazmat-truckload').change(function() {
                if(this.checked) {
                    $( '.unNumber-truckload' ).prop( 'disabled', false );
                    $('.pg-dropdown-truckload')[0].selectize.enable();
                }
                else {
                    $( '.unNumber-truckload' ).prop( 'disabled', true );
                    $('.pg-dropdown-truckload')[0].selectize.disable();
                }
            });

            $('#palletized-truckload').change(function() {
                if(this.checked) {
                    $( '#floor-loaded-truckload' ).prop( 'disabled', false );
                }
                else {
                    $( '#floor-loaded-truckload' ).prop( 'disabled', true );
                }
            });

            $('#over-dimensional-truckload').change(function() {
                if(this.checked) {
                    $( '#odLength' ).prop( 'disabled', false );
                    $( '#odWeight' ).prop( 'disabled', false );
                }
                else {
                    $( '#odLength' ).prop( 'disabled', true );
                    $( '#odWeight' ).prop( 'disabled', true );
                }
            });

            //Equipment Collapse
            $('#van-etrack-truckload').change(function() {
                $('#etrack-bars-truckload').attr('disabled',!this.checked)
                $('#etrack-straps-truckload').attr('disabled',!this.checked)
            });

            function disableEtrackTruckload() {
                document.getElementById("etrack-bars-truckload").disabled = true;
                document.getElementById("etrack-straps-truckload").disabled = true;
            }

            function enableEtrackTruckload() {
                document.getElementById("etrack-bars-truckload").disabled = false;
                document.getElementById("etrack-straps-truckload").disabled = false;
            }

            $('.dry-van-options').on('click',function(){
                var id = $(this).attr('id');

                $('.dryVan-bars-truckload .add').attr('disabled', true);
                $('.dryVan-bars-truckload .minus').attr('disabled', true);
                $('.dryVan-straps-truckload .add').attr('disabled', true);
                $('.dryVan-straps-truckload .minus').attr('disabled', true);
                disableEtrackPartial();

                if (id === 'van-standard-truckload'){
                    disableEtrackTruckload();
                    $( '#van-wood-truckload' ).prop( 'checked', false );
                    $( '#van-plated-truckload' ).prop( 'checked', false );
                    $( '#van-etrack-truckload' ).prop( 'checked', false );
                } else if (id === 'van-wood-truckload'){
                    disableEtrackTruckload();
                    $( '#van-standard-truckload' ).prop( 'checked', false );
                } else if (id === 'van-plated-truckload'){
                    disableEtrackTruckload();
                    $( '#van-standard-truckload' ).prop( 'checked', false );
                    $( '#van-etrack-truckload' ).prop( 'checked', false );
                } else if (id === 'van-etrack-truckload'){
                    enableEtrackTruckload();
                    $( '#van-standard-truckload' ).prop( 'checked', false );
                    $( '#van-plated-truckload' ).prop( 'checked', false );

                    enableEtrackTruckload();
                    $('.dryVan-bars-truckload .add').attr('disabled', false);
                    $('.dryVan-bars-truckload .minus').attr('disabled', false);
                    $('.dryVan-straps-truckload .add').attr('disabled', false);
                    $('.dryVan-straps-truckload .minus').attr('disabled', false);
                }
            });

            $('#collapse1Truckload').change(function(){
                var primary = $('#dry-van-truckload');
                var option1 = $('#van-standard-truckload');
                var option2 = $('#van-wood-truckload');
                var option3 = $('#van-plated-truckload');
                var option4 = $('#van-etrack-truckload');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#collapse1Truckload').change(function(){
                var primary = $('#flatbed-truckload');
                var option1 = $('#flatbed-standard-truckload');
                var option2 = $('#flatbed-stepdeck-truckload');
                var option3 = $('#flatbed-fourFoot-truckload');
                var option4 = $('#flatbed-eightFoot-truckload');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#collapse1Truckload').change(function(){
                var primary = $('#specialized-truckload');
                var option1 = $('#specialized-lowboy-truckload');
                var option2 = $('#lowboy-gooseneck-truckload');
                var option3 = $('#lowbpy-either-truckload');
                var option4 = $('#specilaized-curtain-truckload');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });

            $('#equipmentTruckload-3').change(function(){
                var primary = $('#specialized-lowboy-truckload');
                var pickup = $('#lowboy-gooseneck-truckload');
                var delivery = $('#lowbpy-either-truckload');

                if (!primary.is(':checked')) {
                    $( pickup ).prop( 'checked', false );
                    $( delivery ).prop( 'checked', false );
                    pickup.prop('disabled', true);
                    delivery.prop('disabled', true);
                } else if (primary.is(':checked')) {
                    pickup.prop('disabled', false);
                    delivery.prop('disabled', false);
                }
            });


            $('#collapse1Truckload').change(function(){
                var primary = $('#refrigeratedVan-truckload');
                var option1 = $('#refridgerated-high-truckload');
                var option2 = $('#refrigerated-low-truckload');
                var option3 = $('#refrigerated-standard-truckload');
                var option4 = $('#refrigerated-cube-truckload');

                if (!primary.is(':checked')) {
                    $( option1 ).prop( 'checked', false );
                    $( option2 ).prop( 'checked', false );
                    $( option3 ).prop( 'checked', false );
                    $( option4 ).prop( 'checked', false );
                    option1.prop('disabled', true);
                    option2.prop('disabled', true);
                    option3.prop('disabled', true);
                    option4.prop('disabled', true);
                }
                else if (primary.is(':checked')) {
                    option1.prop('disabled', false);
                    option2.prop('disabled', false);
                    option3.prop('disabled', false);
                    option4.prop('disabled', false);
                }
            });


            //collapse js for accessorials and equipment

            $('.secondary-level').click(function(e){
                e.stopPropagation();
            });

            $('#quote-modal').on('click', '.primary-level', function() {
                $('ul.inner').removeClass('in');
                $(this).addClass('in');
            });

            $('.quote-form input[type="submit"]').on('click', function() {
                $('.quote-form').addClass('submitted');
            });
        },
        /**
         * Initialize form
         */
        init: function() {
            form._setupSelectize();
            form._setupEventHandlers();
        }
    };

    $('.pg-dropdown-partial').selectize({
        placeholder: 'PG'
    });

    $('.pg-dropdown-ltl').selectize({
        placeholder: 'PG'
    });

    $('.pg-dropdown-truckload').selectize({
        placeholder: 'PG'
    });

    $('.freight-class-dropdown-ltl').selectize({
        placeholder: 'Freight Class'
    });


    $('#quote-modal')
        .on('click', '.pg-dropdown select option', function() {
            //get the value of the data attribute
            var valueName = $(this).text();
            console.log(valueName);
            $('.pg-dropdown select').attr('value', valueName);
        });

    var quoteModal = {
        _initializeDatePicker: function() {
            $('input.daterange').daterangepicker({
                // "singleDatePicker": true,
                "opens": 'embed',
                // "dateLimit": '5 days',
                "autoApply": true,
                //"startDate": addDays(1),
                //"endDate": addDays(3)
            });

            $('input.daterange').val('');
        },

        _setupEventHandlers: function() {
            $('#quote-modal a.close-button').click(function () {
                $('#quote-modal').removeClass('visible');
                $('.quote-modal-overlay').removeClass('active');
            });

            $('#nav-modal a.quote-button').click(function () {
                $('#quote-modal').addClass('visible');
                $('.quote-modal-overlay').addClass('active');
            });
            $('.footer-top-menu a.quote-button').click(function () {
                $('#quote-modal').addClass('visible');
                $('.quote-modal-overlay').addClass('active');
            });
            $('a[href="#quote-modal"]').on('click', function(e) {
                $('#quote-modal').addClass('visible');
                $('.quote-modal-overlay').addClass('active');
            });

            //check if the modal has class visible
            //if it has the class visible, add the class active to the .quote-modal-overlay
            //if it doesn't have the class visible, remove the class active from teh .quote-modal-overlay
            //

        },
        init: function() {
            quoteModal._initializeDatePicker();
            quoteModal._setupEventHandlers();
        }
    };

    var infoModal = {
        active: '',
        // _closeModalClickHandler: function(e) {
        //     if (infoModal.active && !$(e.target).closest('.info-modal.visible').length > 0) {
        //         infoModal._closeModal(infoModal.active);
        //     }
        // },
        _openModal: function (id) {
            $('#' + id).addClass('visible');
            $('#quote-modal .overlay').addClass('visible');
            // $(document).on('click', infoModal._closeModalClickHandler);
            infoModal.active = id;
        },
        _closeModal: function (id) {
            $('#' + id).removeClass('visible');
            $('#quote-modal .overlay').removeClass('visible');
            infoModal.active = '';
        },
        _setupEventHandlers: function() {
            $('.info-modal a.close-button.modal-close').click(function () {
                var id = $(this).closest('.info-modal').attr('id');
                infoModal._closeModal(id);
            });

            $('.calculator-modal a.close-button.modal-close').click(function () {
                var id = $(this).closest('.calculator-modal').attr('id');
                infoModal._closeModal(id);
            });

            $('#quote-modal a.info').click(function (e) {
                e.stopPropagation();
                var id = $(this).data('target');
                infoModal._openModal(id);
            });
        },
        init: function() {
            infoModal._setupEventHandlers();
        }
    };



    /**
     * Initialize modal
     */
    function init() {
        form.init();
        quoteModal.init();
        infoModal.init();
    }

    init();

})(jQuery);
