(function($){

    var footerSwiper = {
        swiper: undefined,
        init: function() {
            footerSwiper.swiper = $('.footer-swiper .swiper-wrapper').slick({
                infinite: true,
                dots: true,
                autoplay: true,
                autoplaySpeed: 3000
            });
        }
    };
    var footer = {
        init: function() {
            var footerTopMenuItems = $('.footer-top-menu-item'),
                timeline = new TimelineMax();

            timeline.staggerTo(footerTopMenuItems, 1.5, { y: '-=230', ease: Elastic.easeOut.config(1, 0.6) }, 0.1);

            var controller = new ScrollMagic.Controller(),
                scene = new ScrollMagic.Scene({
                    triggerHook: 1,
                    offset: function(){
                        var offset = 0;
                        if (window.innerWidth < 768) {
                            offset = 325;
                        } else if (window.innerWidth < 992) {
                            offset = 310
                        } else {
                            offset = 380;
                        }
                        return offset;
                    }(),
                    triggerElement: $('#footer-trigger')
                })
                    .setTween(timeline)
                    .addTo(controller);
        }
    };

    var footerModal = {
        active: '',
        open: function (id) {
            $('#' + id).addClass('active');
            $('.footer-modal-overlay').addClass('active').attr('data-open-modal', id);
            //$('body').css('position', 'fixed');
            $(window).disablescroll();
            footerModal.active = id;
        },
        close: function (id) {
            $('#' + id).removeClass('active');
            $('.footer-modal-overlay').removeClass('active');
            //$('body').css('position', '');
            $(window).disablescroll('undo');
            footerModal.active = '';
        },
        init: function() {
            // Attach event listeners
            $('.launch-footer-modal').click(function (e) {
                var id = $(this).data('target');
                footerModal.open(id);
            });

            $('.footer-modal-overlay').click(function (e) {
                if (!$(this).hasClass('active')) {
                    return;
                }

                var id = $(this).attr('data-open-modal');
                footerModal.close(id);
            });
        }
    };

    $('.footer-modal a.close-button').click(function () {
        $('.footer-modal-overlay').click();
    });

    $(document).on('ready', function() {
        if (!$('body').hasClass('home') && !$('body').hasClass('services')) {
            footer.init();
        }
        footerModal.init();
        $(window).trigger('resize');
    });

    $(window).on('resize', debounce(function() {
        if (window.innerWidth < 544) {
            if (footerSwiper.swiper == undefined) {
                footerSwiper.init();
            }
        } else {
            if (footerSwiper.swiper !== undefined) {
                footerSwiper.swiper.slick('unslick');
                footerSwiper.swiper = undefined;
            }
        }


    }, 100));

    /**
     * David walsh debounce function
     * @param func
     * @param wait
     * @param immediate
     * @returns {Function}
     */
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    // window.footerModal = footerModal;
})(jQuery);